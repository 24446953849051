import validateBankAccountNumber from './validateBankAccountNumber'

export default function (number) {
	if (typeof number === 'undefined' || !validateBankAccountNumber(number)) {
		return 'Invalid bank account number'
	} else {
		const originalString = number.toString().replace(/\s/g, '')
		const separator = ' '

		const string =
			originalString.slice(0, 4) +
			separator +
			originalString.slice(4, 6) +
			separator +
			originalString.slice(6, 11)

		return string
	}
}
